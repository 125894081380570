import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate, Link } from 'react-router-dom';
import api from './Interceptor'; // Ensure this file exports the correct axios instance
import {jwtDecode} from 'jwt-decode'; // Ensure you have jwt-decode installed
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
  Alert
} from '@mui/material';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setError('Please fill in all fields.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await api.post('/UserLogin/login', { email, password }, {
        withCredentials: true // This allows cookies to be sent and received
      });
      const { token } = response.data; // Ensure your API response includes a token

      // Store the token in localStorage
      Cookies.set('authToken', token, { expires: 1, secure: true, sameSite: 'Strict' });
      const decodedToken = jwtDecode(token);
      const { id, role } = decodedToken;

      // Navigate to home page with state
      navigate('/index', { state: { id, role }, replace: true });
      // if (role === 'Admin') {
      //   await api.post('/Levels/add-daily-bonus');
      window.location.reload(); 
      // }
    } catch (err) {
    
      setError('Login failed. Please check your credentials.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 4, marginTop: '5%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: '#FAFCFD',
          p: 4,
          borderRadius: 2,
          boxShadow: 3
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ color: '#007bff' }}>
          Login
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ mb: 2 }}
          />
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mb: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
          </Button>
          <Link to="/userRegister" style={{ textDecoration: 'none', color: '#007bff' }}>
            Go to Register
          </Link>
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
