import axios from 'axios';
import Cookies from 'js-cookie';

// Create an Axios instance
const api = axios.create({
  baseURL: '/api',
  timeout: 100000,
  proxy: {
    '/api': 'http://localhost:5000',
  },
});
/*
const api = axios.create({
  baseURL: 'http://localhost:5000/api', // Directly pointing to the API
  timeout: 100000,
});
*/ 
// Request interceptor to add the token to headers
api.interceptors.request.use(
  (config) => {
    debugger
    const token = Cookies.get('authToken'); // Read token from cookies
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    console.log('Request Config:', config);
    return config;
  },
  (error) => {
    console.error('Request Error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
    console.log('Response:', response.data); 
    return response;
  },
  (error) => {
    console.error('Response Error:', error); 
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access (e.g., token expiry)
      console.error('Unauthorized access. Please log in again.');
      Cookies.remove('authToken'); // Remove token from cookies
    }
    return Promise.reject(error);
  }
);

export default api;
