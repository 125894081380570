import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Container, Typography, Card, CardContent, CircularProgress, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton, InputAdornment } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PaidIcon from '@mui/icons-material/Paid';
import StarIcon from '@mui/icons-material/Star';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import HistoryIcon from '@mui/icons-material/History';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import Cookies from 'js-cookie'; 
import SimpleBottomNavigation from './SimpleBottomNavigation';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Api } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import api from './Interceptor';
function Wallet() {
  const [walletData, setWalletData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
const[transferDialogOpen,setTransferDialogOpen]=useState(false)
  const [p2pAmount, setP2pAmount] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
  const [accountId, setAccountId] = useState('');
  const [pinDialogOpen, setPinDialogOpen] = useState(false);
  const [amount, setAmount] = useState('');
  const [pin, setPin] = useState('');   
  const navigate = useNavigate();

  const [receiverAccountId, setReceiverAccountId] = useState('');
  const [verifiedReceiverAccountId, setVerifiedReceiverAccountId] = useState('');
  useEffect(() => {

    const token = Cookies.get('authToken'); 
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.sub; // Assuming 'sub' contains the userId

        if (userId) {
          fetchWalletData(userId);
        } else {
          setError('User ID not found in token');
          setLoading(false);
        }
      } catch (error) {
        setError('Failed to decode token');
        setLoading(false);
      }
    } else {
      setError('No authentication token found');
      setLoading(false);
    }
  }, []);
  const fetchWalletData = async (userId) => {
    try {
      const response = await fetch(`https://localhost:7010/api/Levels/${userId}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setWalletData(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  

  const handleNavigateToHistory = () => {
    debugger
    const userRegistrationId = walletData.userRegistrationId; // Get the userRegistrationId from state
    if (userRegistrationId) {
      debugger
      navigate(`/userHistory/${userRegistrationId}`); // Navigate with userRegistrationId
    } else {
      console.warn("User registration ID is not set.");
    }
  };
  const handleAddToWallet = async (balance, userId) => {
    try {
      debugger
      if (balance < 20) {
        setMessage('Your balance is less than 20.');
        setOpen(true);
        return;
      }

      const response = await api.put(`/Levels/${userId}`, {
        directBalance: balance,
      });
     

      const result = await response.json();
      // Handle success
      alert('Balance successfully added to wallet!');
      // You might want to update UI or state here
    } catch (error) {
      setError(error.message);
      setOpen(true); // Optionally show error message in Snackbar
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handlePinDialogOpen = () => {
    setPinDialogOpen(true);
  };

  const handlePinDialogClose = () => {
    setPinDialogOpen(false);
    setPin('');
  };
  const handleOpenDialog = () => {
    setTransferDialogOpen(true);
  };
  const handlePinVerify = (event) => {
    setPin(event.target.value);
  };
  // Handler to close the dialog
  const handleCloseDialog = () => {
    setTransferDialogOpen(false);
  };
  
  // Handler to save the pin
  const handleSavePin = async () => {
    try {
      debugger
      const isPinValid = await verifyPin(walletData.userRegistrationId, pin);

      if (isPinValid) {
        await handleP2PTransfer(); // Perform the transfer
        handleCloseDialog(); // Close the dialog
      } else {
        setErrorMessage('Invalid pin. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying pin:', error);
      setErrorMessage('An error occurred while verifying the pin. Please try again.');
    }
  };

  // Function to verify pin by making an API call
  const verifyPin = async (userRegistrationId, pin) => {
    try {
      debugger
      const response = await fetch('https://localhost:7010/api/P2PPin/verify', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userRegistrationId, pin }),
      });

     
 

      // Assuming the API returns a success message or status
      return response.status === 200; // or data.isValid if the API returns this field
    } catch (error) {
      console.error('Error fetching pin validation:', error);
      return false;
    }
  };


  const handleDialogClose = () => {
    setDialogOpen(false);
    setP2pAmount('');
    setAccountId('');
  };

  const handleP2PTransfer = async () => {
    if (!verifiedReceiverAccountId == !accountId) {
      setMessage('Please provide and Account ID are same.');
      setOpen(true);
      return;
    }
  
    // Prepare the data to be sent
    const transferData = {
      amount:amount,
      senderAccountId: walletData.userRegistrationId, // Assuming `accountId` is the sender's account ID
      receiverAccountId: receiverAccountId,
      dateOfRequest: new Date().toISOString() // Current date and time in ISO format
    };
  
    try {
      debugger
      // Make the POST request to the API
      const response = await axios.post('https://localhost:7010/api/P2PTransfer/transfer',transferData 
       
      );
  
      // Assuming the transfer is successful
    
      setOpen(true);
      fetchWalletData(walletData.userRegistrationId);
      setMessage('P2P Transfer successful.');
    } catch (error) {
      console.error('P2P Transfer Error:', error);
      setMessage('P2P Transfer failed. Please try again.');
      setOpen(true);
    }

    handleDialogClose();
  };
  
  const handleFetchTransactions = async () => {
    const transactionData = {
      status: 1, 
      amount: walletData.walletAmount,
      dateOfRequest: new Date().toISOString(),
   
      userRegistrationId: walletData.userRegistrationId,
    };
    try {
      debugger
      const response = await axios.post('https://localhost:7010/api/UserTransactions', transactionData);

      if (response.status !== 200) {
        throw new Error('Failed to send transaction data');
      }
     
      setMessage('Transaction data sent successfully.');
      setOpen(true);
      fetchWalletData(walletData.userRegistrationId);
    } catch (error) {
      setMessage(`Error: ${error.message}`);
      setOpen(true);
    }
  };
  const handleCreatePin = async () => {
    const PinData = {
      pin:pin,
      userRegistrationId: walletData.userRegistrationId,
    };
    try {
      debugger
      const response = await axios.post('https://localhost:7010/api/P2PPin', PinData);

     
      setMessage('Transaction data sent successfully.');
      setOpen(true);
      fetchWalletData(walletData.userRegistrationId);
    handlePinDialogClose(); // Close the dialog after creating the pi
    }catch (error) {
      setMessage(`Error: ${error.message}`);
      setOpen(true);
  };
}
  

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  return (
    <div>
      <Container>
        {/* Main Wallet Card */}
        <Card variant="outlined" sx={{ mt: 4, mb: 2, marginTop: '10%', padding: 2, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          <AttachMoneyIcon sx={{ verticalAlign: 'middle', mr: 1, color: 'primary.main' }} />
          Main Wallet
        </Typography>
        <Typography variant="h5" align="center" color="textPrimary">
        {walletData && walletData.walletAmount != null ? `${walletData.walletAmount} USDT` : '0.00 USDT'}
        </Typography>

        <div style={{ backgroundColor: '#6c757d', height: '6px', marginBottom: '16px' }}></div>

        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '8px' }}>
        <Button
                variant="contained"
                color="secondary"
                sx={{ flex: 1, mr: 1 }} // Adjust margin-right for spacing
                onClick={handleDialogOpen}
              >
                P2P Transfer
              </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ flex: 1, mr: 1 }}// Add some margin-top for spacing
            onClick={() => handleFetchTransactions()}
          >
            Withdraw
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ flex: 1, mr: 1 }}// Add some margin-top for spacing
            onClick={()=>handleNavigateToHistory(walletData.userRegistrationId)}
          >
            History 
          </Button>

        </div>
      </CardContent>
    </Card>

        {/* Referral Balance Card */}
        <Card variant="outlined" sx={{ mb: 2, padding: 2, boxShadow: 2 }}>
  <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <div>
      <Typography variant="h6" gutterBottom>
        <PaidIcon sx={{ verticalAlign: 'middle', mr: 1, color: 'secondary.main' }} />
        Referral Balance
      </Typography>
      <Typography color="textPrimary">
      {walletData && walletData.referralBalance != null ? `${walletData.referralBalance} USDT` : '0.00 USDT'}
      </Typography>
    </div>
    <Button
        variant="contained"
        color="primary"
        onClick={() => handleAddToWallet(walletData?.referralBalance || 0, walletData.userRegistrationId)}
      >
        Add to Wallet
      </Button>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={error ? 'error' : 'warning'}>
          {error || message}
        </Alert>
      </Snackbar>
  </CardContent>
</Card>


        {/* Star Balance Card */}
        <Card variant="outlined" sx={{ mb: 2, padding: 2, boxShadow: 2 }}>
  <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <div>
      <Typography variant="h6" gutterBottom>
        <StarIcon sx={{ verticalAlign: 'middle', mr: 1, color: 'warning.main' }} />
        Star Balance
      </Typography>
      <Typography color="textPrimary">
      {walletData && walletData.starBalance != null ? `${walletData.starBalance} USDT` : '0.00 USDT'}
      </Typography>
    </div>
    <Button variant="contained" color="primary" onClick={() => handleAddToWallet(walletData?.starBalance || 0, walletData.userRegistrationId)}>
      Add to Wallet
    </Button>
  </CardContent>
</Card>


        {/* Direct Balance Card */}
        <Card variant="outlined" sx={{ mb: 2, padding: 2, boxShadow: 2 }}>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <Typography variant="h6" gutterBottom>
              <GroupAddIcon sx={{ verticalAlign: 'middle', mr: 1, color: 'success.main' }} />
              Direct Balance
            </Typography>
            <Typography color="textPrimary">
            {walletData && walletData.directBalance != null ? `${walletData.directBalance} USDT` : '0.00 USDT'}
            </Typography>
            </div>
            <Button variant="contained" color="primary" onClick={() => handleAddToWallet(walletData?.directBalance || 0, walletData.userRegistrationId)}>
              Add to Wallet
            </Button>
          </CardContent>
        </Card>

        {/* Total Direct Card */}
        <Card variant="outlined" sx={{ mb: 2, padding: 2, boxShadow: 2 }}>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <Typography variant="h6" gutterBottom>
              <GroupAddIcon sx={{ verticalAlign: 'middle', mr: 1, color: 'success.main' }} />
              Total Direct
            </Typography>
            <Typography color="textPrimary">
              {walletData?.totalDirect || '0'}
            </Typography>
            </div>
            {/* <Button variant="contained" color="primary" onClick={() => handleAddToWallet(walletData?.totalDirect || 0)}>
              Add to Wallet
            </Button> */}
          </CardContent>
        </Card>
        {walletData?.starBalance != null && (
  <Card variant="outlined" sx={{ mb: 2, padding: 2, boxShadow: 2 }}>
    <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

    <div>
    <Typography variant="h6" gutterBottom>
        <MonetizationOnIcon sx={{ verticalAlign: 'middle', mr: 1, color: 'success.main' }} />
        Fortune Balance
      </Typography>
      
      <Typography color="textPrimary">
        {walletData.accountBalance}
      </Typography>
    </div>
    </CardContent>
  </Card>
)}



        {/* Total Withdrawal Card */}
        <Card variant="outlined" sx={{ mb: 2, padding: 2, boxShadow: 2 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              <HistoryIcon sx={{ verticalAlign: 'middle', mr: 1, color: 'info.main' }} />
              Total withdrawal
            </Typography>
            <Typography color="textPrimary">
           {walletData && walletData.totalWithdral != null ? `${walletData.totalWithdral} USDT` : '0.00 USDT'}
            </Typography>
          </CardContent>
        </Card>
        <br/>
        <br/>
        <br/>
        <br/>
      </Container>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
      <DialogTitle>P2P Transfer</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Amount"
          type="number"
          fullWidth
          variant="standard"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />

        <TextField
          margin="dense"
          label="Receiver Account ID"
          fullWidth
          variant="standard"
          value={receiverAccountId}
          onChange={(e) => setReceiverAccountId(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Verify Receiver Account ID"
          fullWidth
          variant="standard"
          value={verifiedReceiverAccountId}
          onChange={(e) => setVerifiedReceiverAccountId(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
      <Button color="primary" onClick={handlePinDialogOpen}>
                Create Pin
              </Button>
        <Button onClick={handleDialogClose} color="primary">
          Cancel
        </Button>
       
        <Button onClick={handleOpenDialog} color="primary">
          Transfer
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog open={pinDialogOpen} onClose={handlePinDialogClose}>
        <DialogTitle>Create Pin</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Pin"
          
            fullWidth
            variant="standard"
            value={pin}
            onChange={(e) => setPin(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePinDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreatePin} color="primary">
            Create Pin
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={transferDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Enter Pin</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Pin"
            type="text"
            fullWidth
            variant="outlined"
            value={pin}
            onChange={handlePinVerify}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSavePin} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    
    </div>
  );
}

export default Wallet;
