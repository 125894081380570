import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams for URL parameters
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Typography, Box, Container, InputBase } from '@mui/material';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import jwt_decode from 'jwt-decode'; // If needed for decoding JWT

// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.default,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const StyledPagination = styled(TablePagination)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

function UserHistory() {
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Get user ID from URL parameters
  const { userId } = useParams();

  useEffect(() => {
    fetchData();
  }, [userId]); // Fetch data when userId changes

  const fetchData = async () => {
    try {
      // Ensure userId is defined before making the API call
      if (!userId) {
        console.warn("User ID is not provided");
        return;
      }

      const response = await axios.get(`https://localhost:7010/api/UserTransactions/by-user/${userId}`);
      // Filter transactions to include only those with status = 2 (Pending)
      const pendingTransactions = response.data.filter(item => item.status === 2);
      setData(pendingTransactions);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  };

  // Map transaction status to the display string
  const getStatusLabel = (status) => {
    switch (status) {
      case 1:
        return "Pending";
      case 2:
        return "Completed";
      case 3:
        return "Rejected";
      default:
        return "Unknown";
    }
  };

  // Filter data based on search input
  const filteredData = data.filter(item =>
    item.id.toString().includes(searchInput)
  );

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container sx={{ backgroundColor: '#f4f4f9', minHeight: '100vh', paddingY: 4, marginTop: '5%' }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ color: "#0056b3", mb: 4 }}>
        User-History
      </Typography>

      <StyledInput
        placeholder="Search by Id"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>S. No.</StyledTableCell>
              <StyledTableCell>Id</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Amount</StyledTableCell>
              <StyledTableCell>Date of Request</StyledTableCell>
              <StyledTableCell>Status Update Date</StyledTableCell>
              {/* <StyledTableCell>Actions</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
              <StyledTableRow key={item.id}>
                <TableCell>{(page * rowsPerPage) + index + 1}</TableCell>
                <TableCell>{item.id}</TableCell>
                <TableCell>{getStatusLabel(item.status)}</TableCell>
                <TableCell>{item.amount}</TableCell>
                <TableCell>{new Date(item.dateOfRequest).toLocaleString()}</TableCell>
                <TableCell>{item.statusUpdateDate ? new Date(item.statusUpdateDate).toLocaleString() : "N/A"}</TableCell>
                {/* <TableCell>
                  {item.status === 2 && (
                    <Button variant="contained" color="primary">
                      Completed
                    </Button>
                  )}
                  {item.status === 3 && (
                    <Button variant="contained" color="error" onClick={() => alert("Rejected action triggered")}>
                      Rejected
                    </Button>
                  )}
                </TableCell> */}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <StyledPagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  );
}

export default UserHistory;
