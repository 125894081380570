import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Import axios for making API requests
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
  Alert,
  FormHelperText
} from '@mui/material';
import api from './Interceptor';

function UserRegister() {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [mobile, setMobile] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [referrerID, setReferrerID] = useState('');
  const [signupDate, setSignupDate] = useState(new Date().toISOString());
 
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // Function to get query parameters from URL
    const query = new URLSearchParams(location.search);
    const referrerIDFromQuery = query.get('referrerID');
    
    if (referrerIDFromQuery) {
      setReferrerID(referrerIDFromQuery);
    }
  }, [location.search]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});
  
    const newErrors = {};
  
    // Basic validation
    if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords don't match.";
    }
  
    if (!/^[a-zA-Z\s]{1,30}$/.test(name)) {
      newErrors.name = 'Name must be less than 30 characters and contain only letters.';
    }
  
    if (address.length > 60) {
      newErrors.address = 'Address cannot be more than 60 characters.';
    }
  
    if (!/^\d{10}$/.test(mobile)) {
      newErrors.mobile = 'Mobile number must be exactly 10 digits.';
    }
  
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.email = 'Invalid email format.';
    }
  
    if (!referrerID) {
      newErrors.referrerID = 'ReferrerID is required.';
    }
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }
  
    // Consolidate form data into a single object
    const userData = {
      name,
      address,
      email,
      password,
      confirmPassword,  // Including confirmPassword for completeness, though it might not be needed server-side
      mobile,
      walletAddress,
      referrerID,
      signupDate: new Date(signupDate).toISOString(),
    };
  
    try {
      console.log('Sending request with data:', userData); // Debugging statement
      const response = await api.post('/UserRegistration', userData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      console.log('Registration Success:', response.data); // Debugging statement
      navigate('/Login');
    } catch (err) {
      if (err.response) {
        console.error('Registration Error:', err.response.data); // Debugging statement
        setErrors({ api: err.response.data.message || 'Registration failed. Please try again.' });
      } else {
        console.error('Network Error:', err.message); // Debugging statement
        setErrors({ api: 'Network error. Please check your connection.' });
      }
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 8 ,marginTop:'5%'}}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: '#FAFCFD',
          p: 4,
          width:500,
          borderRadius: 2,
          boxShadow: 3,
        
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ color: '#48ff00' }}>
          User Registration
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={Boolean(errors.name)}
            helperText={errors.name}
            maxLength="30"
            pattern="[A-Za-z\s]+"
            title="Name must only contain letters and spaces."
            sx={{ mb: 2 }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="address"
            label="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            error={Boolean(errors.address)}
            helperText={errors.address}
            maxLength="60"
            sx={{ mb: 2 }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={Boolean(errors.email)}
            helperText={errors.email}
            sx={{ mb: 2 }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="confirmPassword"
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={Boolean(errors.confirmPassword)}
            helperText={errors.confirmPassword}
            sx={{ mb: 2 }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="mobile"
            label="Mobile"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            error={Boolean(errors.mobile)}
            helperText={errors.mobile}
            pattern="\d{10}"
            title="Mobile number must be exactly 10 digits."
            sx={{ mb: 2 }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="walletAddress"
            label="Wallet Address"
            value={walletAddress}
            onChange={(e) => setWalletAddress(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
          margin="normal"
          fullWidth
          id="referrerID"
          label="Referrer ID"
          value={referrerID}
          onChange={(e) => setReferrerID(e.target.value)}
          sx={{ mb: 2 }}
          helperText={errors.referrerID}
        />
        
          <TextField
            margin="normal"
            fullWidth
            id="signupDate"
            label="Signup Date"
            // type="date"
            value={signupDate.split('T')[0]}
            // onChange={(e) => setSignupDate(new Date(e.target.value).toISOString())}
            sx={{ mb: 2 }}
          />
          {errors.api && <Alert severity="error" sx={{ mb: 2 }}>{errors.api}</Alert>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{ mt: 2 }}
        
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Register'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default UserRegister;
