import React from 'react';
import { Container, Grid, Card, CardContent, Typography, CssBaseline, CardActionArea } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Particles from 'react-particles';
import { loadSlim } from 'tsparticles-slim';
import { 
  AutoGraph as AutoGraphIcon, 
  People as PeopleIcon, 
  Lightbulb as LightbulbIcon, 
  Diamond as DiamondIcon, 
  Handshake as HandshakeIcon 
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

// Step 1: Create a theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h5: {
      fontWeight: 600,
    },
    body2: {
      fontSize: '1rem',
    },
  },
});

// Particles configuration
const particlesOptions = {
  background: {
    color: {
      value: '#282c34', // Darker background color
    },
  },
  particles: {
    color: {
      value: '#ffffff',
    },
    links: {
      color: '#ffffff',
      distance: 150,
      enable: true,
      opacity: 0.5,
      width: 1,
    },
    collisions: {
      enable: true,
    },
    move: {
      direction: 'none',
      enable: true,
      outModes: {
        default: 'bounce',
      },
      random: false,
      speed: 4, // Reduced speed for a subtler effect
      straight: false,
    },
    number: {
      density: {
        enable: true,
        area: 800,
      },
      value: 60, // Reduced number for a cleaner look
    },
    opacity: {
      value: 0.3, // Reduced opacity for a softer look
    },
    shape: {
      type: 'circle',
    },
    size: {
      value: { min: 1, max: 5 },
    },
  },
  interactivity: {
    detectsOn: 'canvas',
    events: {
      onClick: {
        enable: true,
        mode: 'push',
      },
      onHover: {
        enable: true,
        mode: 'repulse',
      },
    },
  },
  detectRetina: true,
};

const particlesInit = async (engine) => {
  await loadSlim(engine);
};

const particlesLoaded = async (container) => {
  await console.log(container);
};

// Styled Card component with hover effects
const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  backgroundColor: 'rgba(0, 0, 0, 0.7)', // Slightly transparent to highlight text
  color: 'white',
  height: '400px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: theme.shadows[5],
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[10],
  },
}));

// Icon mapping
const iconMap = {
  'Decentralized Earnings System': <DiamondIcon fontSize="large" />,
  'MLM Network Benefits': <PeopleIcon fontSize="large" />,
  'Smart Contract Integration': <AutoGraphIcon fontSize="large" />,
  'Community Growth Opportunities': <HandshakeIcon fontSize="large" />,
  'Advanced Analytics Dashboard': <LightbulbIcon fontSize="large" />,
};

const Home = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* Particles Background */}
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={particlesOptions}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: -1,
        }}
      />

      {/* Hero Section */}
      <Container sx={{ mt: 6, mb: 6 }}>
        <Grid container spacing={4} justifyContent="center">
          {[1, 2, 3].map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ backgroundColor: 'rgba(255 , 255, 255, 0.9)', boxShadow: 3 }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Feature {index + 1}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Detailed description of feature {index + 1}. Highlight the benefits and key points.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Full-Width Information Cards */}
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <StyledCard>
              <CardActionArea>
                <CardContent sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {iconMap['Decentralized Earnings System']}
                  <Typography variant="h6" component="div" sx={{ mt: 2, mb: 1 }}>
                    Decentralized Earnings System
                  </Typography>
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    Explore our decentralized earning system that allows for transparent and efficient transactions within our MLM network.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledCard>
              <CardActionArea>
                <CardContent sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {iconMap['MLM Network Benefits']}
                  <Typography variant="h6" component="div" sx={{ mt: 2, mb: 1 }}>
                    MLM Network Benefits
                  </Typography>
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    Learn about the benefits of joining our MLM network, including unlimited earning potential and the power of leveraging your network.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </StyledCard>
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12}>
            <StyledCard>
              <CardActionArea>
                <CardContent sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {iconMap['Smart Contract Integration']}
                  <Typography variant="h6" component="div" sx={{ mt: 2, mb: 1 }}>
                    Smart Contract Integration
                  </Typography>
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    Discover how our smart contract integration ensures secure and automated payouts, making your earnings seamless and reliable.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </StyledCard>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 4 }}>
          <Grid item xs={12} sm={6}>
            <StyledCard>
              <CardActionArea>
                <CardContent sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {iconMap['Community Growth Opportunities']}
                  <Typography variant="h6" component="div" sx={{ mt: 2, mb: 1 }}>
                    Community Growth Opportunities
                  </Typography>
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    Get involved in community growth opportunities and watch your earnings multiply as your network expands.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledCard>
              <CardActionArea>
                <CardContent sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {iconMap['Advanced Analytics Dashboard']}
                  <Typography variant="h6" component="div" sx={{ mt: 2, mb: 1 }}>
                    Advanced Analytics Dashboard
                  </Typography>
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    Access our advanced analytics dashboard to track your earnings, monitor your network’s performance, and optimize your strategy.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </StyledCard>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default Home;
